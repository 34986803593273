import { graphql, useStaticQuery } from "gatsby"
//allStrapiAuditorios(sort: {order: ASC, fields: orden}) {

const useAuditorios = () => {
  const resultado = useStaticQuery(
    graphql`
      query {
        allStrapiAuditorios {
          nodes {
            auditorio_oculto
            nombre
            id
            title
            title_en
            title_pt
            nombre_en
            nombre_pt
            width
            top
            left
            boton_size
            boton_color_fondo
            boton_color_letra
            boton_variante
            color_fondo_marco
            video_en_activado
            video_pt_activado
            imagen_cartelera_mostrar
            imagen_cartelera {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 600
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    `
  )
  return resultado.allStrapiAuditorios.nodes.map(auditorio => ({
    nombre: auditorio.nombre,
    imagen_cartelera: auditorio.imagen_cartelera,
    id: auditorio.id,
    title: auditorio.title,
    title_pt: auditorio.title_pt,
    nombre_pt: auditorio.nombre_pt,
    title_en: auditorio.title_en,
    nombre_en: auditorio.nombre_en,
    imagen_cartelera_mostrar: auditorio.imagen_cartelera_mostrar,
    width: auditorio.width,
    top: auditorio.top,
    boton_color_letra: auditorio.boton_color_letra,
    boton_color_fondo: auditorio.boton_color_fondo,
    color_fondo_marco: auditorio.color_fondo_marco,
    boton_size: auditorio.boton_size,
    boton_variante: auditorio.boton_variante,
    left: auditorio.left,
    auditorio_oculto: auditorio.auditorio_oculto,
    video_en_activado: auditorio.video_en_activado,
    video_pt_activado: auditorio.video_pt_activado,
  }))
}

export default useAuditorios
