import React from "react"
import { Box, Tooltip, Button } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image"
import urlSlug from "url-slug"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import "../css/background-image.css"

const AuditorioPreview = ({ auditorio, intl }) => {
  const {
    nombre,
    imagen_cartelera,
    title,
    title_en,
    title_pt,
    nombre_en,
    nombre_pt,
    boton_size,
    boton_color_fondo,
    boton_color_letra,
    boton_variante,
    color_fondo_marco,
    imagen_cartelera_mostrar,
  } = auditorio

  const currentLocale = intl.locale
  const titulo =
    currentLocale === "en"
      ? title_en
      : currentLocale === "pt"
      ? title_pt
      : title
  const nombreAuditorio =
    currentLocale === "en"
      ? nombre_en
      : currentLocale === "pt"
      ? nombre_pt
      : nombre

  if (imagen_cartelera_mostrar) {
    return (
      <Box
        style={{ background: `${color_fondo_marco}`, padding: 20 }}
        onClick={() => navigate(`/${urlSlug(nombre)}`)}
      >
        {/* <Link to={`/${urlSlug(nombre)}`} className={link}> */}
        {imagen_cartelera !== null && imagen_cartelera.localFile !== null ? (
          <GatsbyImage
            image={imagen_cartelera.localFile.childImageSharp.gatsbyImageData}
            alt="imagen"
          />
        ) : (
          ""
        )}
        {/*  </Link> 
        <Link to={`/${urlSlug(nombre)}`} className={link}>*/}
        <p style={{ color: `${boton_color_letra}`, textAlign: "center", margin: `10px auto` }} //className={titulocss}
        >
          {nombreAuditorio}
        </p>
        {/* </Link> */}
      </Box>
    )
  } else {
    return (
      <div className="fondoBoton">
        {/* <Link to={`/${urlSlug(nombre)}`} className={link}> */}
        <Tooltip title={titulo} arrow placement="top">
          <Button
            fullWidth
            variant={boton_variante}
            onClick={() => navigate(`/${urlSlug(nombre)}`)}
            style={{
              background: `${boton_color_fondo}`,
              color: `${boton_color_letra}`,
            }}
            size={boton_size}
          >
            {nombreAuditorio}
          </Button>
        </Tooltip>
        {/*  </Link> */}
      </div>
    )
  }
}

export default injectIntl(AuditorioPreview)
