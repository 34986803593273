import React, { useState, useEffect } from "react"
import useAuditorios from "../hooks/useAuditorios"
import AuditorioPreview from "./AuditorioPreview"

const ListadoAuditoriosMovil = () => {
  const resultado = useAuditorios()

  const [auditorios, guardarAuditorios] = useState([])

  useEffect(() => {
    guardarAuditorios(resultado)
  }, [])

  return (
    <>
      {auditorios.map(auditorio => (
        <ul key={auditorio.id}>
          {auditorio.auditorio_oculto ? (
            ""
          ) : (
            <AuditorioPreview key={auditorio.id} auditorio={auditorio} />
          )}
        </ul>
      ))}
    </>
  )
}

export default ListadoAuditoriosMovil
